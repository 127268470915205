/* eslint-disable react/prop-types */
import Script from "next/script"
import Head from "next/head"
import { clientConfig } from "highline/commonjs/config"
import "../styles/common.css"

export default function MyApp({ Component, pageProps }) {
  /*
    Move the <link rel=canonical/> to this leval to avoid skipping duplication or update by NextJs
    https://stackoverflow.com/questions/68204973/avoid-duplicate-meta-description-and-keywords-in-next-js
  */
  let canonicalPath = "/"
  if (pageProps?.initialProps) {
    canonicalPath = pageProps?.initialProps.canonicalPath
  }

  return (
    <>
      <Head>
        <link key="canonical-1" href={`https://bonobos.com${canonicalPath}`} rel="canonical" />
        <meta content="width=device-width initial-scale=1" name="viewport" />
      </Head>
      <script
        type="text/javascript"
        dangerouslySetInnerHTML={{
          __html: `window.__HIGHLINE_CONFIG__ = ${JSON.stringify(clientConfig)}`,
        }}
      ></script>
      <Component {...pageProps} />
    </>
  )
}
