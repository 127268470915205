const { Map } = require("immutable")

const environment = process.env.APP_ENV
const isTest = environment === "test"
const isDevelopment = environment === "development"
const isReview = environment === "review"
const isStaging = environment === "staging"
const isProduction = environment === "production"
const isDevelopmentLike = isDevelopment || isTest
const isProductionLike = !isDevelopmentLike

const constructorDisableCheck = (envVar) => {
  switch (envVar) {
    case "true":
      return "constructor-off"
    default:
      return "constructor-on"
  }
}

// Client application config
//
// WARNING: make sure no application secrets are included here,
// these variables are accessible on the client
const clientConfig = Map({
  // Environments
  environment,
  isTest,
  isDevelopment,
  isReview,
  isStaging,
  isProduction,
  isDevelopmentLike,
  isProductionLike,

  // Config from env vars
  abTastyKey: process.env.AB_TASTY_KEY,
  accountCreationOnConfirmationEnabled:
    process.env.ACCOUNT_CREATION_ON_CONFIRMATION_ENABLED === "true",
  adminClientAuthToken: process.env.FLATIRON_ADMIN_CLIENT_AUTH_TOKEN,
  affirmApiKey: process.env.AFFIRM_API_KEY,
  affirmPaymentEnabled: process.env.AFFIRM_PAYMENT_ENABLED === "true",
  // affirm apr must be expressed as a decimal (i.e. 0.1 for 10% APR)
  affirmAPR: process.env.AFFIRM_APR,
  affirmMinimumThreshold: process.env.AFFIRM_MINIMUM_THRESHOLD,
  // affirm months can only be 3, 6, or 12
  affirmMonths: process.env.AFFIRM_MONTHS,
  affirmScriptUrl: process.env.AFFIRM_SCRIPT_URL,
  apiUrl: process.env.FLATIRON_API_URL,
  // This flag has value between 0 and 1. 0=completely disabled, 0.2=enabled for 20% of users, 1=completely enabled.
  applePayEnableThreshold: parseFloat(process.env.APPLE_PAY_EXPRESS_CHECKOUT_ROLLOUT_PERCENT),
  assetPrefix: process.env.ASSET_HOST || "",
  brandCode: process.env.BRAND_CODE,
  ccSecurityCodeValidationEnabled: process.env.CC_SECURITY_CODE_VALIDATION_ENABLED === "true",
  clientAuthToken: process.env.FLATIRON_CUSTOMER_CLIENT_AUTH_TOKEN,
  constructorKey: process.env.CONSTRUCTOR_KEY,
  constructorPlpNumResultsPerPageDesktop: process.env.CONSTRUCTOR_PLP_NUM_RESULTS_PER_PAGE_DESKTOP,
  constructorPlpNumResultsPerPageMobile: process.env.CONSTRUCTOR_PLP_NUM_RESULTS_PER_PAGE_MOBILE,
  constructorSearchNumResultsPerPageDesktop:
    process.env.CONSTRUCTOR_SEARCH_NUM_RESULTS_PER_PAGE_DESKTOP,
  constructorSearchNumResultsPerPageMobile:
    process.env.CONSTRUCTOR_SEARCH_NUM_RESULTS_PER_PAGE_MOBILE,
  isConstructorSearchDisabled: constructorDisableCheck(process.env.IS_CONSTRUCTOR_SEARCH_DISABLED),
  contentfulAccessToken: process.env.CONTENTFUL_ACCESS_TOKEN,
  contentfulEnvironment: process.env.CONTENTFUL_ENVIRONMENT,
  contentfulPreviewAccessToken: process.env.CONTENTFUL_PREVIEW_ACCESS_TOKEN,
  contentfulPreviewHost: process.env.CONTENTFUL_PREVIEW_HOST,
  contentfulSpace: process.env.CONTENTFUL_SPACE,
  isContentfulDisabled: process.env.IS_CONTENTFUL_DISABLED === "true",
  disableCartRecommendations: process.env.DISABLE_CART_RECOMMENDATIONS === "true",
  disableCategorySavedItemCta: process.env.DISABLE_CATEGORY_SAVED_ITEM_CTA === "true",
  disableColorFilters: process.env.DISABLE_COLOR_FILTERS === "true",
  disableLoqate: process.env.DISABLE_LOQATE === "true",
  disableSvsGiftCards: process.env.DISABLE_SVS_GIFT_CARDS === "true",
  enableApplePayExpressCheckout: process.env.APPLE_PAY_EXPRESS_CHECKOUT_ENABLED === "true",
  enableGifting: process.env.ENABLE_GIFTING === "true",
  isGuestCheckoutEnabled: process.env.ENABLE_GUEST_CHECKOUT === "true",
  enablePaypalExpressCheckout: process.env.PAYPAL_EXPRESS_CHECKOUT_ENABLED === "true",
  disableWarehouseTransitionMessaging:
    process.env.DISABLE_WAREHOUSE_TRANSITION_MESSAGING === "true",
  estimatedDeliveryDateEnabled: process.env.ESTIMATED_DELIVERY_DATE_ENABLED === "true",
  friendbuyEmailVerificationWidgetId: process.env.FRIENDBUY_EMAIL_VERIFICATION_WIDGET_ID,
  friendbuyReferralWidgetId: process.env.FRIENDBUY_REFERRAL_WIDGET_ID,
  google2faEnabled:
    process.env.FEATURE_MODE === "true" || isTest
      ? false
      : process.env.NEXT_PUBLIC_GOOGLE_2FA_ENABLED === "true",
  googleLoginClientId: process.env.NEXT_PUBLIC_GOOGLE_LOGIN_CLIENT_ID,
  googleHostedDomain: process.env.NEXT_PUBLIC_GOOGLE_HOSTED_DOMAIN,
  googleReCaptchaSiteKey: process.env.GOOGLE_RECAPTCHA_SITE_KEY,
  hotjarId: process.env.HOTJAR_ID,
  hotjarSv: process.env.HOTJAR_SV,
  isCSSAnimationEnabled: !process.env.FEATURE_MODE === "true",
  isFeatureMode: process.env.FEATURE_MODE === "true",
  loqateApiKey: process.env.LOQATE_API_KEY,
  loqateStyleUrl: process.env.LOQATE_STYLE_URL,
  loqateScriptUrl: process.env.LOQATE_SCRIPT_URL,
  newRelicApplicationId: process.env.NEW_RELIC_APPLICATION_ID,
  newRelicAccountId: process.env.NEW_RELIC_ACCOUNT_ID,
  newRelicAgentId: process.env.NEW_RELIC_AGENT_ID,
  newRelicBrowserLicenseKey: process.env.NEW_RELIC_BROWSER_LICENSE_KEY,
  newRelicEnabled: process.env.NEW_RELIC_ENABLED === "true",
  numPdpRecommendations: parseInt(process.env.NUM_PDP_RECOMMENDATIONS || 5),
  numZeroResultsRecommendations: parseInt(process.env.NUM_ZERO_RESULTS_RECOMMENDATIONS || 5),
  perimeterXAppId: process.env.PERIMETER_X_APP_ID,
  powerReviewsApiKey: process.env.POWER_REVIEWS_API_KEY,
  powerReviewsIsPdpEnabled:
    !(process.env.FEATURE_MODE === "true") && process.env.POWER_REVIEWS_IS_PDP_ENABLED === "true",
  powerReviewsMerchantGroupId: process.env.POWER_REVIEWS_MERCHANT_GROUP_ID,
  powerReviewsMerchantId: process.env.POWER_REVIEWS_MERCHANT_ID,
  powerReviewsApiUrl: process.env.POWER_REVIEWS_API_URL,
  rollbarClientAccessToken: process.env.ROLLBAR_CLIENT_ACCESS_TOKEN,
  salesforceChatCommunityUrl: process.env.SALESFORCE_CHAT_COMMUNITY_URL,
  salesforceChatEnabled: process.env.SALESFORCE_CHAT_ENABLED === "true",
  salesforceChatEnvId: process.env.SALESFORCE_CHAT_ENV_ID,
  salesforceChatPrimaryQueueButtonId: process.env.SALESFORCE_CHAT_PRIMARY_QUEUE_BUTTON_ID,
  salesforceChatSecondaryQueueButtonId: process.env.SALESFORCE_CHAT_SECONDARY_QUEUE_BUTTON_ID,
  salesforceChatSecondaryQueueFilter: process.env.SALESFORCE_CHAT_SECONDARY_QUEUE_FILTER,
  salesforceChatUrl: process.env.SALESFORCE_CHAT_URL,
  salesforceConversionUrl: process.env.SALESFORCE_CONVERSION_URL,
  salesforceMobileChatDisabled: process.env.SALESFORCE_MOBILE_CHAT_DISABLED === "true",
  segmentKey: process.env.SEGMENT_CLIENT_WRITE_KEY,
  // if this is set to true, this will automatically rewrite unsecured http:// url to https:// for the assets we are accessing
  sslMode: process.env.SSL_MODE !== "false",
  supportContactUsUrl: process.env.SUPPORT_CONTACT_US_URL,
  supportFeedbackUrl: process.env.SUPPORT_FEEDBACK_URL,
  supportHelpUrl: process.env.SUPPORT_HELP_URL,
  supportReturnsUrl: process.env.SUPPORT_RETURNS_URL,
  isRollbarSourceMapEnabled: isProductionLike && process.env.SOURCE_MAP_ENABLED === "true",
  ccpaIntake: process.env.CCPA_INTAKE,
  ccpaUrlAffirmation: process.env.CCPA_URL_AFFIRMATION,
  ccpaUrlRedirect: process.env.CCPA_URL_REDIRECT,
  ccpaBffAzureEnabled: process.env.CCPA_BFF_AZURE_ENABLED === "true",
  ccpaNewReportEnabled: process.env.CCPA_NEW_REPORT_ENABLED === "true",
  oneTrustScriptUrl: process.env.ONETRUST_SCRIPT_URL,
  showCCPALinks: process.env.SHOW_CCPA_LINKS === "true",
  cookieDomain: process.env.COOKIE_DOMAIN,
  bonobosSessionCookieKey: process.env.BONOBOS_SESSION_COOKIE_KEY,
  sessionDuration: parseInt(process.env.SESSION_DURATION),
  oneTrustCookiesConsent: process.env.NEXT_PUBLIC_ONE_TRUST_COOKIES_CONSENT,
  oneTrustCookie: process.env.NEXT_PUBLIC_SHOW_ONE_TRUST_COOKIES_BANNER === "true",
  connectTimeoutValue: process.env.CONNECT_TIMEOUT_VALUE,
  oneTrustDataDomainScript: process.env.NEXT_PUBLIC_ONE_TRUST_DATA_DOMAIN_SCRIPT,
})

exports.clientConfig = clientConfig.toJS()

// Server application config
const serverConfig = clientConfig.merge({
  rollbarServerAccessToken: process.env.ROLLBAR_SERVER_ACCESS_TOKEN,
})

// Make each server config var available as a named export
serverConfig.forEach((value, key) => {
  exports[key] = value
})
